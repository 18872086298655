
//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'
import '../../styles/input/custom-date.css'

//utils
import RequestHelper from '../../../../utils/helper';

//components
import DateBox from "devextreme-react/date-box";

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';


const CustomDate = ({ label, value, setValue, requiredValue, labelSize, isDisabled, dateFormat }) => {
  const onDateChanged = e => {
    setValue(e.value)
  }
  return (
        <div className='custom-input' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
            <div className='custom-input__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
            <DateBox
                value={value}
                disabled={isDisabled}
                className='custom-date'
                onValueChanged={onDateChanged}
                displayFormat={RequestHelper.getDatePickerDateFormat()}
                placeholder={dateFormat}
                min={new Date}
            >
            {requiredValue &&
                <Validator>
                    <RequiredRule message={`${label} is required`} />
                </Validator>
            }
            </DateBox>
        </div>
  )
}

export default CustomDate