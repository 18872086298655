import {useEffect,useState} from 'react'
import {useParams} from 'react-router-dom'

//components
import OasisContactInfo from '../../../assets/controls/custom-guest-info'

//images
import FloorPlanIcon from '../../../../images/request-icons/floor_plan.svg'
import LocationIcon from '../../../../images/request-icons/location.svg'
import CalendarIcon from '../../../../images/request-icons/calendar.svg'
import MoneyIcon from '../../../../images/request-icons/money.svg'

//utils
import {getOasisContact} from '../../../../utils/my-requests'

//styles
import '../../styles/property-main-information.css'
import RequestHelper from '../../../../utils/helper'

const PropertyMainInformation = ({data,refMap}) => {
  const params = useParams()
  const [formattedDate, setFormattedDate] = useState({
    checkIn: null,
    checkOut: null,
    days: null,
    nights: null,
    checkInTime: null,
    checkOutTime: null
  })
  const [oasisContactInfo, setOasisContactInfo] = useState({})
  const scrollToElement = _ =>{
    window.scrollTo(0, refMap.current.offsetTop - 90)
  }

  const getOasisContactInfo = async requestId => {
    let oasisContactInfoData = await getOasisContact(requestId)
    setOasisContactInfo(oasisContactInfoData)
  }
  useEffect(() => {
    params?.requestId && getOasisContactInfo(params.requestId)
  }, [params])
  
  return (
    <div className='request-option__property-main-information'>
        <div className='request-option__property-main-information__section1'>
            <div className='request-option__property-main-information__section1__info'>
                <div className='request-option__property-main-information__section1__info__item request-option__property-main-information__section1__info__item--property-name'>{data.option.propertyName}</div>
                <div className='request-option__property-main-information__section1__info__item'><img src={FloorPlanIcon} alt=""/><span>{data.option.floorPlan.name}</span></div>
                <div className='request-option__property-main-information__section1__info__item'><img src={LocationIcon} alt=""/><span>{data.option.propertyFormattedAddress}</span><span className='request-option__property-main-information__section1__info__item__link' onClick={scrollToElement}>(Show on map)</span></div>
                <div className='request-option__property-main-information__section1__info__calendar'>
                  <div className='request-option__property-main-information__section1__info__item'>
                          <img src={CalendarIcon} alt="" />
                          <span>{RequestHelper.getDurationString(data.option.checkInDate, data.option.checkOutDate)}</span>
                      </div>
                      <div className='request-option__property-main-information__section1__info__calendar__time'>{RequestHelper.getArrivalDepartureString(data.option.checkInDate, data.option.checkOutDate)}</div>
                </div>
                <div className='request-option__property-main-information__section1__info__item'><img src={MoneyIcon} alt=""/><span className='request-option__property-main-information__section1__info__item__bold'>Final Rate: </span> <span>{data.option.currencyName} {data.option.finalRate} / Daily</span></div>
            </div>
            <div className='request-option__property-main-information__section1__guest'>
              {(oasisContactInfo.fullName || oasisContactInfo.email || oasisContactInfo.phone) && <OasisContactInfo isReadOnly={true} readOnlyName={oasisContactInfo.fullName} readOnlyEmail={oasisContactInfo.email} readOnlyPhone={oasisContactInfo.phone} readOnlyTitle='Oasis Contact Info'/>}
            </div>
        </div>
        
        <hr />
        <div className='request-option__property-main-information__section2'>
            <div className='request-option__property-main-information__section2__subsection'>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>One Time Fee’s:</span>
                    {data.option.oneTimeFees ? 
                      <span>{data.option.currencyName} {data.option.oneTimeFees.toFixed(2)}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Security Dep:</span>
                    {data.option.securityDep ? 
                      <span>{data.option.currencyName} {data.option.securityDep.toFixed(2)}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Property Grade:</span>
                    {data.option.gradeName  ? 
                      <span>{data.option.gradeName}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Gated:</span>
                    {data.option.isGated ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Controlled Building Access:</span>
                    {data.option.controlledAccess ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Neighborhood:</span>
                    {data.option.neighborhood ? 
                      <span>{data.option.neighborhood}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                {/* <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Background Req:</span>
                    {data.option.oneTimeFees ? 
                      <span>{data.option.oneTimeFees.toFixed(2)}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    } 
                </div> */}
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Parking:</span>
                    {data.option.parkingAccessibility ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Parking Details:</span>
                    {data.option.parkingDetails ? 
                      <span>{data.option.parkingDetails}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Minimum Stay (days):</span>
                    {data.option.minimumStay ? 
                      <span>{data.option.minimumStay}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
            </div>
            <div className='request-option__property-main-information__section2__subsection'>
                <div>
                  Terms And Services:
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Unit Type:</span>
                    {data.option.unitType ? 
                      <span>{data.option.unitType}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Floor Level:</span>
                    {data.option.floorLevel  ? 
                      <span>{data.option.floorLevel}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Square Footage:</span>
                    {data.option.squareFootage  ? 
                      <span>{data.option.squareFootage} (sq {data.option.squareFootageUnitName})</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Telephone:</span>
                    {data.option.telephone ? 
                      <span>{data.option.telephone}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Maid Service:</span>
                    {data.option.maidFrequency ? 
                      <span>{data.option.maidFrequency}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Smoking Unit:</span>
                    {data.option.isSmokingUnit ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    }
                </div>
                {/* <div>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Same Day Turn Clean:</span>
                    {data.option.parkingAccessibility ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    } 
                </div> */}
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Notice Required (Days):</span>
                    {data.option.noticeRequired ? 
                      <span>{data.option.noticeRequired}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Termination Policy:</span>
                    {data.option.terminationPolicy ? 
                      <span>{data.option.terminationPolicy}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
            </div>
            <div className='request-option__property-main-information__section2__subsection'>
                <div>
                  Pet Info:
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'># of Pets Allowed:</span>
                    {data.option.numberOfPets ? 
                      <span>{data.option.numberOfPets}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Pet Rent:</span>
                    {data.option.petRent  ? 
                      <span>{data.option.currencyName} {data.option.petRent.toFixed(2)} (per pet, included in FINAL RATE)</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Pet Fee:</span>
                    {data.option.petFee  ? 
                      <span>{data.option.currencyName} {data.option.petFee.toFixed(2)} (per pet, not refundable)</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Pet Deposit:</span>
                    {data.option.petsDep ? 
                      <span>{data.option.currencyName} {data.option.petsDep.toFixed(2)} (per pet, not refundable)</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Pet Park on Property:</span>
                    {data.option.isPetPark ? 
                      <span>Yes</span>
                      :
                      <span>No</span>
                    }
                </div>
                <div className='request-option__property-main-information__section2__subsection__item'>
                    <span className='request-option__property-main-information__section2__subsection--bold'>Pet Policy:</span>
                    {data.option.petPolicy ? 
                      <span>{data.option.petPolicy}</span>
                      :
                      <span className='request-option__property-main-information__section2__subsection--missing'>Missing</span>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default PropertyMainInformation