import {Link} from 'react-router-dom'

//images
import CalendarIcon from '../../../images/request-icons/calendar.svg'
import FloorPlanIcon from '../../../images/request-icons/floor_plan.svg'
import LocationIcon from '../../../images/request-icons/location.svg'
import ViewIcon from '../../../images/admin-icons/eye-icon.svg'
import {ReactComponent as DeleteIcon} from '../../../images/admin-icons/trash-icon.svg'

//styles
import '../styles/request-properties-card.css'
import RequestHelper from '../../../utils/helper'
import { putCancelRequestedProperty } from "../../../utils/my-requests"

const RequestPropertiesCard = ({ id, floorPlan, name, building, image, address, dates, hiddeActions, declineReason, requestId }) => {
    const cancelRequestedProperty = async () => {
        await putCancelRequestedProperty(requestId, id)
    }

  return (
    <div className='request-properties-card'>
        <div className='request-properties-card__img'>
                <img
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    textIndent: '-10000px'
                  }}
                  alt={""}
                />
        </div>
        <div className='request-properties-card__name'>
            <span>{name}</span>
            <span className='request-properties-card__name__building'>{building}</span> 
        </div>
        <div className='request-properties-card__address'>
            <img src={LocationIcon} alt={""} /><span>{address}</span>
        </div>
        <div className='request-properties-card__floor'>
            <img src={FloorPlanIcon} alt={""} /><span>{floorPlan}</span>
        </div>
        <div className='request-properties-card__dates'>
            <img src={CalendarIcon} alt={""} /><span>{dates}</span>
        </div>
          <div className='request-properties-card__status'>
              <span className='request-properties-card__status__tag'>{RequestHelper.getRequestPropertyStatus(declineReason)}</span>
          </div>
        <div className='request-properties-card__buttons'>
            <div className='request-properties-card__buttons__content'>
                <Link target='_blank' to={`/property/dashboard/${id}`} className='request-properties-card__buttons__content__view'><img src={ViewIcon}/><span>View</span></Link>
                  {!hiddeActions && <div className='request-properties-card__buttons__content__delete'><DeleteIcon/><span onClick={cancelRequestedProperty}>Delete</span></div>}
            </div>
        </div>
        <div className='request-properties-card__divisor'></div>
    </div>
  )
}

export default RequestPropertiesCard